import { useLocationData } from 'hooks/useLocationData';
import { useCallback, useState } from 'react';
import { LocationOptionType } from 'types/location';
import { CountryCodeEnum } from 'types/shared-types';
import { formatCanadianProvinceOptions, getCanadaTaxExemptionJurisdictions } from 'utils';

type StateFormatterType = 'ca_exemption' | 'ca_taxJurisdiction' | 'default';

interface UseStateOptionsProps {
  formatter?: StateFormatterType;
}

export const useStateOptions = ({ formatter = 'default' }: UseStateOptionsProps = {}) => {
  const { getStates } = useLocationData();
  const [stateOptions, setStateOptions] = useState<LocationOptionType[]>([]);

  const formatStates = useCallback(
    (states: LocationOptionType[], countryCode: string) => {
      if (countryCode !== CountryCodeEnum.CA) return states;

      switch (formatter) {
        case 'ca_exemption':
          return getCanadaTaxExemptionJurisdictions(states);
        case 'ca_taxJurisdiction':
          return formatCanadianProvinceOptions(states);
        default:
          return states;
      }
    },
    [formatter]
  );

  const fetchStateOptions = useCallback(
    async (countryCode?: string) => {
      if (!countryCode) {
        return setStateOptions([]);
      }
      const states = await getStates(countryCode);
      const formattedStates = formatStates(states, countryCode);
      setStateOptions(formattedStates);
    },
    [getStates, formatStates]
  );

  return {
    stateOptions,
    fetchStateOptions,
  };
};
